import api from "@/services/apiService"

class AccountService {

  saveLevel(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/level', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateLevel(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/level/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getLevels() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/level')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  cancelLevel(_id) {
    return new Promise((resolve, reject) => {
      api.delete(process.env.VUE_APP_BACKEND_API + 'account/level/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  reactiveLevel(_id) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'account/level/' + _id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getAccountTemplate() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/template')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  saveCompanyAccount(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getAccounts() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateProfile(_id, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'account/profile/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  changePassword(_id, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'account/password/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getAccountSettingGroup() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/setting-group')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateAccountSettingGroup(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/setting-group', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getAccountSettingPayrates() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/setting-payrate')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateAccountSettingPayrate(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/setting-payrate', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  deposit(id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/deposit/' + id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  withdraw(id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/withdraw/' + id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getAccountSettingMarkets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/setting-markets')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  saveAccountSettingMarkets(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/setting-markets', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getAccountSettingLottosets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/setting-lottoset')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  updateAccountSettingLottosets(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/setting-lottoset', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }
}

export default new AccountService()
